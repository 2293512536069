<template>
  <div
    id="Aluno_Inicial"
    class="pl-1"
    v-if="
      validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)
    "
  >
    <Loader v-if="showLoader" />
    <h2 class="mb-3 title main-title">
      Bem-vindo(a), Aluno {{ this.$store.getters.getUserDatas.name }}
    </h2>
    <div class="here bg-edf0f5 py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <transition-group name="fade">
              <div
                key="atual-exercicio"
                class="text-center bg-fff px-4 shadow-sm"
              >
                <div>
                  <h2 class="title title-padding">
                    Aprendendo o básico: Parte 02
                  </h2>
                  <div class="dica mt-3">
                    <p>
                      Dicas do exercício: <br />
                      {{ content }}
                    </p>
                  </div>
                  <div class="tabuleiro py-5 position-relative">
                    <router-link
                      :to="'/aluno/meus-temas/3'"
                      class="color-fff btn-retomar-licao text-uppercase position-absolute centraliza-absolute bg-0e5caf d-flex align-items-center justify-content-center"
                      >retomar lição
                      <i class="ml-1 mb-1"
                        ><img
                          src="@/assets/aluno/iconContinuar.png"
                          alt="iconContinuar.png"/></i
                    ></router-link>
                    <div class="div-tabuleiro-img">
                      <img
                        src="@/assets/aluno/tabuleiro.png"
                        alt="tabuleiro.png"
                        class="opacity-medium"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
          <div class="bord-l col-md-5">
            <transition-group name="fade">
              <div
                key="atual-progresso"
                class="my-progress bg-fff chamaFormularoModal shadow-sm"
              >
                <div id="progress" class="px-4">
                  <h2 class="title color-42cc7e title-padding">
                    Meu Progresso:
                  </h2>
                  <div class="pai-progress position-relative">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <span
                        class="completo"
                        :style="
                          `${
                            progressWidth > 45 ? 'color:#000' : 'color:#42cc7e'
                          }`
                        "
                        >{{
                          progressWidth < 10
                            ? `0${progressWidth}`
                            : progressWidth
                        }}/100</span
                      >
                    </div>
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="`width: ${progressWidth}%;`"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="carreiraStatus mt-3">
                    <div class="row mr-0">
                      <div class="col-md-7 offset-md-5 d-flex">
                        <div>
                          <div
                            class="icone-carreira nivelIconClass position-relative"
                            :style="
                              `background-image: url(${require('@/assets/aluno/peao.png')});`
                            "
                          >
                            <img
                              class="iconePosicaoCarreira position-absolute"
                              src="@/assets/aluno/iconePosicaoCarreira.png"
                              alt="iconePosicaoCarreira.png"
                            />
                          </div>
                        </div>
                        <div class="atual-faltando opacity-more">
                          <div>
                            <span>1/100</span>
                          </div>
                        </div>
                        <div class="position-relative">
                          <img
                            class="cadeado position-absolute"
                            src="@/assets/aluno/cadeado.png"
                            alt="cadeado.png"
                          />
                          <div class="opacity-more">
                            <div class="icone-carreira">
                              <div
                                class="icone-carreira nivelIconClass"
                                :style="
                                  `background-image: url(${require('@/assets/aluno/rei.png')});`
                                "
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="text-center color-0e5caf pb-3 pt-1 mx-4">
                    <strong>Parabéns, você é um {{ nivelAlunoPeca(1) }}</strong>
                    <br />
                    Continue fazendo mais <strong>Lições</strong> para avançar
                    no seu progresso e tornar-se Rei no xadrez.
                  </p>
                </div>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div
                key="atual-status-exercicios"
                class="bg-fff shadow-sm border-default shadow-sm status-aulas"
              >
                <h2 class="title title-padding">Tema: Aprendendo o básico</h2>
                <div>
                  <div class="a-concluir row mx-0 opacity">
                    <div
                      class="col-md-1 d-flex align-items-center justify-content-center arrow"
                    >
                      <img
                        src="@/assets/aluno/Icon-material-label-outline-cinza.png"
                        alt="Icon-material-label-outline.png"
                      />
                    </div>
                    <div class="col-md-11 row mx-0 bg-dfdfdf">
                      <div class="col-md-8 pl-0">
                        <h3 class="text-left title-concluido color-a2a5ab">
                          Aprenda o básico: Parte 03
                        </h3>
                      </div>
                      <div
                        class="col-md-4 pl-0 d-flex align-items-center justify-content-center pr-0 justify-content-between"
                      >
                        <span class="color-a2a5ab">Não iniciado</span>
                        <img
                          src="@/assets/aluno/iconAConcluir.png"
                          alt="iconAConcluir.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="py-3 row mx-0">
                    <div
                      class="scrollable col-md-1 d-flex align-items-center justify-content-center arrow"
                    >
                      <img
                        src="@/assets/aluno/Icon-material-label-outline.png"
                        alt="Icon-material-label-outline.png"
                      />
                    </div>
                    <div class="col-md-11 row mx-0 bg-0e5caf scroll-exercicios">
                      <div class="col-md-8 pl-0">
                        <h3 class="text-left title-concluido color-fff pt-3">
                          {{ exerciciosTemas[0].name }}
                        </h3>
                      </div>
                      <div
                        class="col-md-4 d-flex align-items-center justify-content-center pr-0 justify-content-between"
                      >
                        <span class="color-fff pt-3">Continuar</span>

                        <router-link
                          :to="'/aluno/meus-temas/3'"
                          class="hoverStyleButton"
                        >
                          <img
                            src="@/assets/aluno/iconContinuar.png"
                            alt="iconContinuar.png"
                            class="pt-3"
                          />
                        </router-link>
                      </div>
                      <div class="w-100 status">
                        <div
                          class="exercicio mt-2"
                          style="width: 100%"
                          v-for="(item, index) in exerciciosTemas"
                          :key="index"
                        >
                          <div
                            v-for="(itemExercicios,
                            indexExercicios) in item.exercicios"
                            :key="indexExercicios"
                            class="d-flex justify-content-between"
                          >
                            <span class="color-42cc7e">{{
                              itemExercicios.name
                            }}</span>
                            <div class="img-topico">
                              <img
                                src="@/assets/aluno/exercicioAtual.png"
                                alt="exercicioAtual.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="concluido row mx-0 pb-4 opacity" :hidden="false">
                    <div
                      class="col-md-1 d-flex align-items-center justify-content-center arrow"
                    >
                      <img
                        src="@/assets/aluno/Icon-material-label-outline-verde.png"
                        alt="Icon-material-label-outline.png"
                      />
                    </div>
                    <div class="col-md-11 row mx-0 bg-42cc7e">
                      <div class="col-md-8 pl-0">
                        <h3 class="text-left title-concluido color-fff">
                          Aprenda o básico: Parte 01
                        </h3>
                      </div>
                      <div
                        class="col-md-4 d-flex align-items-center justify-content-center pr-0 justify-content-between"
                      >
                        <span class="color-fff">Completo!</span>
                        <img
                          src="@/assets/aluno/iconCompletou.png"
                          alt="iconCompletou.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It`,
      progressWidth: 50,
      showLoader: false,
      exerciciosTemas: [],
    };
  },
  mounted() {
    this.getTemasExercicios();
  },
  components: {
    Loader,
  },
  methods: {
    getTemasExercicios() {
      this.$store
        .dispatch("getTemas", {
          token: this.$store.getters.getPerfilToken,
          page: 1,
        })
        .then((resolve) => {
          if (resolve.valid) {
            this.showLoader = false;
            this.toastGlobal(
              "success",
              `Lista de exercícios atualizada com sucesso`
            );
            this.exerciciosTemas = resolve.msg.data;
          } else {
            this.showLoader = false;
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu, ${resolve.msg.responseJSON.msg}`
            );
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getUserDatas"]),
  },
};
</script>

<style scoped>
@import "../style/alunoStyle.css";

.icone-carreira {
  background-color: #42cc7e;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

.atual-faltando span {
  font-size: 10px;
}

.atual-faltando > div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0 25px;
  margin-top: -3px;
}

strong {
  font-weight: bolder;
}

.cadeado {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.arrow img {
  width: 15px !important;
  margin-left: 5px;
}

.iconePosicaoCarreira {
  width: 10px;
  right: 2px;
  top: 2px;
}

.div-tabuleiro-img img {
  width: 62%;
}

.img-topico img {
  width: 15px;
  padding: 0;
  margin-right: 2px;
}

.status-aulas h2,
.status-aulas h3 {
  text-align: center;
}

.dica p {
  font-weight: 300;
}

.status span {
  font-weight: 300;
}
</style>
